import { render, staticRenderFns } from "./homeChan.vue?vue&type=template&id=329b8cd3&scoped=true&"
import script from "./homeChan.vue?vue&type=script&lang=js&"
export * from "./homeChan.vue?vue&type=script&lang=js&"
import style0 from "@/styles/pc/home.less?vue&type=style&index=0&id=329b8cd3&lang=less&scoped=true&"
import style1 from "@/styles/pc/homewe.less?vue&type=style&index=1&id=329b8cd3&lang=less&scoped=true&"
import style2 from "./homeChan.vue?vue&type=style&index=2&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "329b8cd3",
  null
  
)

export default component.exports