<!--  -->
<template>
    <div class="homecont">
      <HeadNav></HeadNav>
  
      <div class="homeLun">
        
        <img v-if="categoryIdVal == '21'" class="wecardImg" src="../assets/some/20.jpg" alt="轮播" />
        <img v-else class="wecardImg" src="../assets/some/18.png"  alt="轮播"/>
      </div>
      <div class="homewe">

        <div class="homeChan">
            <div class="homeChanLft">
                <el-collapse v-model="activeNames" @change="fuChangeFun" accordion>
                    <el-collapse-item title="Equipment" name="1" class="aaaa" >
                        <el-collapse>
                            <el-collapse-item v-for="(item,index) in datalist" :key="index" :title="item.name" :name="item.id" >
                                <div class="homeChanLftsall" v-for="(it,index) in item.list" :key="index"
                                @click="handleChangeChild(it.id)" :class="it.status == true ? 'ceshiBaccolor':''">{{it.name}}</div>
                            </el-collapse-item>
                        </el-collapse>
                    </el-collapse-item>
                    <el-collapse-item title="SIC" name="2" class="aaaa">
                        <div class="homeChanLftsall newBox" @click="handleChangeChild('21')">High-purity Conductive SIC powder</div>
                    </el-collapse-item>
                </el-collapse>

                <div class="homeYingForm">
                    <div class="homeWoBox-title">Online message</div>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="84px" class="demo-ruleForm">
                        <el-form-item label="Name" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="Mobile" prop="phone">
                            <el-input v-model="ruleForm.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="Email" prop="email">
                            <el-input v-model="ruleForm.email"></el-input>
                        </el-form-item>
                        <el-form-item label="Country" prop="country">
                            <el-input v-model="ruleForm.country"></el-input>
                        </el-form-item>
                        <el-form-item label="Leaving a message" prop="message" class="ceshidiv">
                            <el-input v-model="ruleForm.message" type="textarea" :rows="2"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

            <div v-if="listShow" class="newBoxChan">
                <div class="homeChanRig" >
                    <div class="homeChanRigBox" v-for="(item,index) in tableList" :key="index" @click="seeDetailsfun(item.id)">
                        <img class="homeChanRigBoxLf" :src="item.imgsUrl[0]" alt="详情"/>
                        <div class="homeChanRigBoxCon">
                            <div class="homeChanRigBoxCon-top">{{item.title}}</div>
                            <div class="homeChanRigBoxCon-btm">{{item.productText}}</div>
                            <div class="homeTuiBoxContleftFontBtm">See more <i class="el-icon-arrow-right"></i></div>
                        </div>
                        
                    </div>
                    <div class="zanweshu" v-if="tableList.length <= 0">暂无数据</div>
                </div>
                <div class="paginationNewbox">
                    <el-pagination  layout="total, sizes, prev, pager, next,jumper"
                    :total="total" :page-size="pageSize" :current-page="currentPage"
                    @current-change="handleCurrentChange"  @size-change="handlePageSize"
                    class="fr mr-20"></el-pagination>
                </div>
            </div>

            <div class="homeChanRigDetails" v-else>
                <div class="homeChanRigDetailsTop newtop">
                    <div class="homeChanRigDetailsTopLf">
                        <el-carousel indicator-position="outside" height="200px">
                            <el-carousel-item v-for="(item, index) in contentDetails.imgsUrl" :key="index">
                                <img class="homeChanRigDetailsTopLfImg" :src="item" alt="lunbo" />
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="homeChanRigDetailsTopRig">
                        <div class="homeChanRigDetailsTopRig-tle">{{contentDetails.title}}</div>
                        
                        <div class="homeChanRigDetailsTopRig-btm" v-if="newcategoryIdVal != '21'">{{contentDetails.productText}}</div>
                        <div class="homeChanRigDetailsTopRig-btm" v-else>
                           
                            •  Third generation semiconductor materials with superior performance;<br/>
                            •  Purity reached 99.99999%;<br/>
                            •  Including needle-like material and granular material;<br/>
                            •  Customizable；<br/>
                            •  By Chemical Vapor Deposition (CVD), SiC powder is produced by decomposition reaction of silicon source and carbon source gas at high temperature. The obtained SiC powder has high purity and the granularity is uniform .
                        </div>
                        
                    </div>
                </div>
                <div class="homeChanRigDetailsBtm">
                    <div class="homeChanRigDetailsBtm-top">Details</div>
                    <div class="homeChanRigDetailsBtm-btm">
                        <p v-html="contentDetails.content" style="white-space:pre-wrap;"></p>
                    </div>
                </div>
            </div>
        </div>

      </div>
      <Bottom></Bottom>
      <Sidebar></Sidebar>
    </div>
  </template>
  
  <script>
  import {Dialog} from 'vant';
  import { APILogin } from "../api/APILogin";
  import HeadNav from "../components/headNav";
  import Bottom from "../components/bottom";
  import Sidebar from "../components/sidebar";
  export default {
    metaInfo: {
      title: 'Product Center',
      meta: [
        {
          name: 'Product Center',
          content: 'SiC powder,Induction Heating Machine,Induction Heating power supply,water Cooling system,Disk CNC quenching machine tool,quenching machine tool,High precision power supply'
        },
        {
          name: 'description',
          content: ' Shanghai Junko New Energy Technology Co., Ltd. is a high-tech enterprise located in Changning District, Shanghai, focusing on the research and development, production, and sales of induction heating technology and semi-conductor materials. We provide efficient and energy-saving induction heating equipment to help improve industrial production efficiency, focus on innovation in semiconductor materials,promote the green energy revolution, and con-tribute to global sustainable development.'
        }
      ]
    },
    name: "homeChan",
    components: {
      HeadNav,
      Bottom,
      Sidebar,
    },
    data() {
      return {
        activeNames: ['1'],
   
        datalist: [

            {
                id: '1',
                name: 'Induction heating power supply',
                list: [
                    {
                        id: '01',
                        name: 'MF/super audio Universal series resonance power supply',
                        status: false,
                    },{
                        id: '02',
                        name: 'High precision series resonance MF/super audio/Ultra high frequency power supply',
                        status: false,
                    },{
                        id: '03',
                        name: 'High precision series resonance all in one /HHT power supply',
                        status: false,
                    },{
                        id: '04',
                        name: 'High precision parallel resonance power supply',
                        status: false,
                    },{
                        id: '05',
                        name: 'Special induction power supply',
                        status: false,
                    },{
                        id: '06',
                        name: 'Synchronous Dual Frequency Induction Heating Machine',
                        status: false,
                    },{
                        id: '07',
                        name: 'Portable Air cooling Induction Heating Machine',
                        status: false,
                    },{
                        id: '08',
                        name: 'Portable Induction Heating Machine',
                        status: false,
                    },{
                        id: '09',
                        name: 'Crystal Growth Induction Heating Machine',
                        status: false,
                    },
                ]
            }, 
            {
                id: '2',
                name: 'CNC quenching machine tool',
                list: [
                    {
                        id: '021',
                        name: 'Vertical CNC hardening machine tool',
                        status: false,
                    },{
                        id: '022',
                        name: 'Disk CNC quenching machine tool',
                        status: false,
                    },{
                        id: '023',
                        name: 'Horizontal hardening machine too',
                        status: false,
                    },{
                        id: '024',
                        name: 'Raceway CNC Quenching Machine for Slewing rings',
                        status: false,
                    },{
                        id: '025',
                        name: 'Whole Teeth Induction Hardening Machine',
                        status: false,
                    },
                ]
            },
            {
                id: '3',
                name: 'Water cooling system',
                list: [
                    {
                        id: '031',
                        name: 'Water cooling system',
                        status: false,
                    },{
                        id: '032',
                        name: 'Industrial water chiller',
                        status: false,
                    },
                ]
            },
            {
                id: '4',
                name: 'Transformer',
                list: [
                    {
                        id: '041',
                        name: 'Handheld transformer',
                        status: false,
                    },{
                        id: '042',
                        name: 'Coaxial transformer',
                        status: false,
                    },
                ]
            },
            {
                id: '5',
                name: 'Auxiliary equipment',
                list: [
                    {
                        id: '051',
                        name: 'H-RB series laser fiber temperature sensor',
                        status: false,
                    },{
                        id: '052',
                        name: 'Energy Monitoring system',
                        status: false,
                    },
                ]
            },

        ],

        ruleForm: {
          name: '',
          country: '',
          email: '',
          message: '',
          phone: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入电话', trigger: 'blur' },
          ],
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
          ],
          message: [
            { required: true, message: '请输入留言', trigger: 'blur' },
          ],
        },

        windowHeight: (document.documentElement.clientHeight - 100),   //实时屏幕高度

        listShow: true, // 列表详情切换
        total: 0,
        currentPage: 1,
        pageSize: 10,
        tableList: [],
        categoryIdVal: '',
        contentDetails: '',
        newtableList: [],

        stateVal: '',

        newcategoryIdVal: '',
      }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {
      $route(to, from) {
            if (to.path === from.path) {
                // 如果目标路由和来源路由的路径相同，则强制刷新页面
                this.$router.go();
            }
        }
    },
    filters: {},
    //方法集合
    methods: {
        // 选择事件
        fuChangeFun(val) {
            var newval = val.toString()
            //console.log("选择事件>>>>>>>>>>",JSON.stringify(newval))
            if(newval == '') {
                this.categoryIdVal = ''
                this.listShow = true
                this.currentPage = 1
                this.pageSize = 10
                this.tableList = []
                this.getDataList()
            }

        },

        // 提交留言
        submitForm() {
            APILogin.messageAdd({
                name: this.ruleForm.name, 
                country: this.ruleForm.country,
                email: this.ruleForm.email, 
                message: this.ruleForm.message,
                phone: this.ruleForm.phone, 
            }).then(res => {
                Dialog.alert({
                    message: 'Thank you for your message. Someone will contact you later',
                })
               console.log("res>>>>" + JSON.stringify(res.data))
            }).catch(fail => {
                console.log("fail>>>>" + JSON.stringify(fail))
            }) 
        },
        // 查看详情
        seeDetailsfun(item) {
            APILogin.getDetailsApi({
                id: item, // 分类id
            }).then(res => {
                res.data.imgsUrl = res.data.imgs.split(',')
                //console.log("res>>>>" + JSON.stringify(res.data.imgsUrl))
                this.contentDetails = res.data
                this.newcategoryIdVal = res.data.categoryId
                this.listShow = false
               // console.log("res>>>>" + JSON.stringify(this.contentDetails))
            }).catch(fail => {
                console.log("fail>>>>" + JSON.stringify(fail))
            }) 
        },
        handleChangeChild(val) {
            //console.log("2222>>>>>>",JSON.stringify(val));
            this.datalist.map(item=>{
                item.list.map(it=>{
                    if(val == it.id) {
                        it.status = true
                    }else {
                        it.status = false
                    }
                })
            })
            this.listShow = true
            this.categoryIdVal = val
            this.currentPage = 1
            this.pageSize = 10
            this.tableList = []
            this.getDataList()
        },

        // 获取产品列表数据
        getDataList() {
            let a = typeof(this.categoryIdVal)
            //console.log(">>>>>>>>>>",a)
            if(a == 'object') {
                this.categoryIdVal = this.categoryIdVal.toString()
            }
            APILogin.getChanList({
                page: this.currentPage, // 分页参几页
                limit: this.pageSize, // 分页参每页几条
                categoryId: this.categoryIdVal, // 分类id
            }).then(res => {
                let b = res.data.data
                b.map(item=>{
                    item.imgsUrl = item.imgs.split(',')
                })
                this.tableList = b
                this.newtableList = b
                this.total = res.data.count
            }).catch(fail => {
                console.log("fail>>>>" + JSON.stringify(fail))
            })
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getDataList()
        },
        handlePageSize(val) {
            this.currentPage = 1
            this.pageSize = val
            this.getDataList()
        },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        document.title = "产品中心"
        this.stateVal = this.$route.query.state || ''
       // console.log("纯子能源11>>>>>>>>>>",JSON.stringify(this.stateVal) ) 
        // 如果是2就用sic的分类id去查数据，如果不是就用默认第一个分类去查数据 21 01
        if(this.stateVal == '2') {
            this.categoryIdVal = '21'
            this.activeNames = []
        }else if(this.stateVal == '1') {
            this.categoryIdVal = ''
            this.activeNames = []
        }
        this.listShow = true
        this.currentPage = 1
        this.pageSize = 10
        this.tableList = []
        this.getDataList()

        this.activeNames = []
        let a = JSON.stringify(this.stateVal)
        this.activeNames.push(a)


        // 详情过来的
        this.stateValBao = this.$route.query.typeVal || ''
        if(this.stateValBao != '') {
            this.listShow = false
            this.seeDetailsfun(this.stateValBao)
        }
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
    //   var that = this
    //   window.onresize = () => {
    //     return (() => {
    //       window.fullHeight = document.documentElement.clientHeight
    //       that.windowHeight = Number(window.fullHeight)  // 高
    //     })()
    //   }

    },
    beforeCreate() {}, // 生命周期 - 创建之前
    beforeMount() {}, // 生命周期 - 挂载之前
    beforeUpdate() {}, // 生命周期 - 更新之前
    updated() {}, // 生命周期 - 更新之后
    beforeDestroy() {}, // 生命周期 - 销毁之前
    destroyed() {}, // 生命周期 - 销毁完成
    activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
  };
  </script>
  <style lang="less" scoped src="@/styles/pc/home.less"></style>
  <style lang="less" scoped src="@/styles/pc/homewe.less"></style>
  <style lang="less">
      .ceshidiv {
      .el-form-item__label {
        line-height: 16px;
        margin-top: 10px;
      }
    }
  .homeYingForm {
    .el-form-item__label {
        line-height: 20px !important
    }
    .el-button--primary {
      width: 100%;
    }
  }
    .zanweshu {
    padding: 100px;
    text-align: center;
    font-size: 24px;
    color: rgba(0, 0, 0, .5);
  }
  .homeChanRigDetailsBtm-btm {
    img {
        margin: 10px 0;
        max-width: 100% !important;
    }
    video {
        width: 100% !important;
        margin: 10px 0;
    }
  }
    .homeChanLft {
        .el-collapse-item__header {
            font-size: 14px;
        }
        .el-collapse-item__header {
            font-weight: 600 !important;
            padding-left: 10px;
        }
        .el-collapse {
            border: 1px solid #EBEEF5;
        }
        .aaaa>div>div {
            // background: #409EFF !important;
            background-color: #409EFF;
            // box-shadow: 2px 4px 10px 0px 
            //     rgba(97, 97, 97, 0.22);
            border: solid 1px rgba(0, 0, 0, .1);
            font-size: 26px;
            color: #fff;
        }
        .el-collapse-item__content {
            padding-bottom: 0 !important
        }
    }

    .ceshiBaccolor {
        // background-color: #409EFF;
        color: #409EFF;
        font-weight: 600;
    }
  
  </style>
  
  
  
  